<template>
  <VForm
    ref="formRef"
    class="container-lg px-0 d-flex flex-wrap position-relative overflow-hidden"
    :validation-schema="schema"
    @submit="login"
  >
    <j-input
      v-model="form.email"
      name="email"
      class-wrap="mb-3 mb-lg-4 w-100"
      type="email"
      placeholder="Email"
    />
    <j-input
      v-model="form.password"
      name="password"
      class-wrap="mb-3 mb-lg-4 w-100"
      type="password"
      placeholder="Password"
    />
    <j-button text="Sign" type="submit" class="w-100" />
  </VForm>
</template>

<script lang="ts">
import JInput from "~/shared/ui/Input.vue";
import JButton from "~/shared/ui/Button.vue";
import handleErrorResponse from "~/shared/lib/ValidationErrorResponse";
import * as Yup from "yup";

export default defineComponent({
  name: "LoginPage",
  components: { JButton, JInput },
  async setup() {
    definePageMeta({
      auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: "/",
      },
    });

    const form = ref({
      email: "",
      password: "",
    });

    const formRef = ref();

    const { signIn } = await useAuth();

    const login = async () => {
      try {
        await signIn(
          {
            email: form.value.email,
            password: form.value.password,
          },
          {
            callbackUrl: "/",
          }
        );
      } catch (e: any) {
        if (e?.response?.status === 422) {
          handleErrorResponse(e.data, formRef);
        }
      }
    };

    const schema = Yup.object({
      email: Yup.string().max(255).email().required().label("E-mail"),
      password: Yup.string().max(255).required().label("Password"),
    });

    return {
      formRef,
      login,
      form,
      schema,
    };
  },
});
</script>
